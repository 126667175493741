<template>
  <div class="view-home">
    <div class="container">
      <div class="quiz-status my-4">
        <div class="card" v-show="countdown">
          <ul>
            <li class="time">
              {{ countdown }}
            </li>
            <li class="recording" v-if="quiz.proctoring">
              {{ $t('testing.recording') }}
            </li>
            <li class="interruption" v-if="quiz.proctoring">
              {{ $t('testing.interruption') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container questions-wrap">
      <h2>
        {{ $t('creativity-15-17.title') }}
      </h2>
      <h3>
        {{ $t('creativity-15-17.subTitle2') }}
      </h3>
      <div class="collaboration-desc" v-html="$t('creativity-15-17.instruction2')">
      </div>

      <div class="d-flex flex-column">
        <div class="question-item" v-if="currentSubtest == 1">
          1. {{ $t('creativity-15-17.task1') }}
          <el-input placeholder="Запиши существительные, которые начинаются на слог «фи»" v-model="inputSubtest1"></el-input>
        </div>


        <div class="question-item" v-if="currentSubtest == 2">
          2. {{ $t('creativity-15-17.task2') }}
          <el-input placeholder="Запиши существительные, которые оканчиваются слогом «ва»" v-model="inputSubtest2"></el-input>
        </div>
      </div>


      <!--      <button @click="sendCollaborationResults">Отправить</button>-->
      <el-button @click="nextSubtest(false)"
                 class="btn btn-primary" v-if="currentSubtest == 1">
        {{ $t('creativity-15-17.next') }}
      </el-button>

      <el-button @click="openModal"
                 class="btn btn-danger" v-if="currentSubtest == 2">{{ $t('testing.finish-modal-button') }}
      </el-button>

    </div>
    <b-modal id="exit-modal" hide-footer hide-header>
      <div id="modal-wrapper">
        <div id="dialog">
          <button @click="$bvModal.hide('exit-modal')" type="button" class="close" data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"></span>
          </button>
          <img class="modal-warning-img mb-3" src="/images/profile/modal-warning.svg">
          <h3 class="mb-0">{{modalTitle}}</h3>
          <p>{{ $t('testing.finish-modal-desc') }}</p>
          <div class="clearfix pt-3">
            <el-button @click="sendCreativitySubtest2Results(false)" class="btn btn-primary mx-2">
              {{ $t('testing.finish-modal-button') }}
            </el-button>
            <a @click="$bvModal.hide('exit-modal')"
               class="btn btn-outline-primary mx-2">{{ $t('testing.finish-modal-cancel') }}</a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import 'katex/dist/katex.min.css';
import Loader from '../../components/Loader';
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue';

Vue.use(VueViewer)
export default {
  name: "Index",
  data() {
    return {
      modalTitle: this.$t('testing.finish-modal-title'),
      timerInterval: null,
      countdown: 0,
      quiz: '',
      answersComplexExplanations: [
        { choice: "", question: "1" },
        { choice: "", question: "2" },
        { choice: "", question: "3" },
        { choice: "", question: "4" },
        { choice: "", question: "5" },
        { choice: "", question: "6" },
        { choice: "", question: "7" },
        { choice: "", question: "8" },
        { choice: "", question: "9" },
      ],
      score: null,
      keysSubtest1: [
        "фиакр", "фиал", "фиалка", "фианит", "фибра", "фибрин", "фибриноген",
        "фибринозный", "фибринолиз", "фиброз", "фигура", "фигурант", "фидуция",
        "физвоспитание", "физзарядка", "физзащита", "физиатр", "физиатрия",
        "физик", "физика", "физиолог", "физиологически", "физиология",
        "физиономия", "физиопрофилактика", "физиопроцедуры", "физиораствор",
        "физиотерапевт", "физкультура", "физкультминутка", "физкультура",
        "физмат", "физматшкола", "физподготовка", "физраствор", "физрук",
        "фиксатор", "фиксирование", "филирование", "филогенез", "филогенетика",
        "филология", "философ", "философия", "фильм", "фильмопроизводство",
        "фильмопрокат", "фильтр", "финалист", "финаль", "финансирование",
        "финансы", "фингал", "финик", "финиш", "финляндия", "фирма", "фирменный",
        "фискал", "фисташка", "фитнес", "фитнесцентр", "фитогормоны", "фитозал",
        "фитол", "фитотерапия", "фиточай", "фитошампунь", "фитоэкстракт",
        "фитоэстрогены", "фитюлечка", "фишка", "фитнес-команда", "фитнес-программа",
        "фитнес-тренер", "физико-математический", "физико-механический",
        "физико-технический", "физико-химический", "физиоактивный", "физиократ",
        "физиолог"
      ],
      keysSubtest2: [
        "авва", "автоподстава", "агава", "айва", "альмавива", "альтернатива",
        "антиква", "билингва", "битва", "братва", "бритва", "брюква", "буква",
        "вдова", "вульва", "глава", "голова", "госсредства", "грива", "два",
        "дезсредства", "держава", "детва", "дива", "директива", "дресва", "дрова",
        "дряква", "дубрава", "едва", "ендова", "жатва", "жертва", "жратва",
        "забава", "застава", "издетства", "инвектива", "инициатива", "интерлингва",
        "канва", "кассава", "кваква", "клюква", "трава", "клятва", "контроктава",
        "конъюнктива", "коричнева", "корова", "королева", "косхалва", "кошева",
        "крапива", "средства", "кружева", "кряква", "купава", "лава", "легислатива",
        "лексредства", "листва", "лихва", "ловитва", "локва", "лягва", "мальва",
        "меньшинства", "электробритва", "мойва", "молва", "молитва", "мольва",
        "мурава", "нажива", "нацменьшинства", "нова", "облава", "обнова", "однова",
        "октава", "олива", "оправа", "орава", "основа", "отава", "отрава",
        "оттудова", "паства", "пахлава", "переправа", "перспектива", "песева",
        "плотва", "погранзастава", "подкова", "подлива", "подоснова", "подпочва",
        "подстава", "пожива", "полуслова", "получувства", "полцарства", "почва",
        "приправа", "расправа", "сверхдержава", "слава", "слева", "слива", "снова",
        "сова", "сперва", "спецсредства"
      ],
      inputSubtest1: '',
      inputSubtest2: '',
      wordsSubtest1 : [],
      wordsSubtest2 : [],
      matchingWordsSubtest1: [],
      matchingWordsSubtest2: [],
      currentSubtest: 1,
      timerNumber: 150,
      interpretationResult: {
        high: 'Высокий уровень креативности («признаки одаренности»). У тебя отличное воображение, ты способен думать быстро, мыслить творчески,   высказывать разнообразные идеи. Рекомендуем пройти 2 этап диагностики в оффлайн формате. Этого нужно записаться на сайте на индивидуальную консультацию к психологу.',
        middle: 'Средний уровень креативности. У тебя хорошее воображение, ты мыслишь достаточно быстро и творчески,  Рекомендуем пройти 2 этап диагностики в оффлайн формате. Этого нужно записаться на сайте на индивидуальную консультацию к психологу. ',
        low: 'Ниже среднего уровень креативности. У тебя хорошее воображение, но ты мог бы думать быстрее, оригинальнее и самостоятельно, придумывать собственные истории. Рекомендуется посещать психологические занятия для развития своего потенциала.',
      }
    }
  },
  methods: {
    countMatches1() {
      this.matchingWordsSubtest1 = this.wordsSubtest1.filter(word => this.keysSubtest1.includes(word));
    },
    countMatches2() {
      this.matchingWordsSubtest2 = this.wordsSubtest2.filter(word => this.keysSubtest2.includes(word));
    },
    handleInput() {
      const rawWords = this.inputSubtest1
          .toLowerCase()
          .split(/[\s,;]+/)
          .filter(word => word);

      this.wordsSubtest1 = [...new Set(rawWords)];

      this.countMatches1()
    },
    handleInput2() {
      const rawWords = this.inputSubtest2
          .toLowerCase()
          .split(/[\s,;]+/)
          .filter(word => word);

      this.wordsSubtest2 = [...new Set(rawWords)];

      this.countMatches2()
    },
    nextSubtest(timer) {

      if (timer) {

        this.currentSubtest = 2
        localStorage.setItem('currentSubtest', "2")
        this.startTimer(this.timerNumber)

        return
      }

      this.handleInput()

      if (!this.wordsSubtest1.length) {

        return Vue.toastr({
          message: this.$t('error-title'),
          description: 'Заполните поле',
          type: 'error'
        })
      } else {
        clearInterval(this.timerInterval);
        this.currentSubtest = 2
        localStorage.setItem('currentSubtest', "2")
        this.startTimer(this.timerNumber)
      }
    },
    openModal() {
      this.$bvModal.show('exit-modal');
    },
    async sendCreativitySubtest2Results(timer) {

      this.handleInput2()

      if (!timer && !this.wordsSubtest2.length) {

        return Vue.toastr({
          message: this.$t('error-title'),
          description: 'Заполните поле',
          type: 'error'
        })
      }

      const creativitySubtest2Data = {
        answers: {inputSubtest1: this.inputSubtest1, inputSubtest2: this.inputSubtest2,},
        score: this.matchingWordsSubtest1.length + this.matchingWordsSubtest2.length,
        interpret: this.interpretScore(this.matchingWordsSubtest1.length + this.matchingWordsSubtest2.length),
        addInfo: {
          matchingWordsSubtest1Array: this.matchingWordsSubtest1,
          matchingWordsSubtest1: this.matchingWordsSubtest1.length,
          matchingWordsSubtest2Array: this.matchingWordsSubtest2,
          matchingWordsSubtest2: this.matchingWordsSubtest2.length
        },
      }

      let params = JSON.parse(localStorage.getItem('quiz-params-15-17'))
      const quizToken = localStorage.getItem('quiz-token')

      params.answer_id = {...params.answer_id, creativitySubtest2Data}
      localStorage.setItem('quiz-params-15-17', JSON.stringify(params))

      const response = await this.$http.post(API_ROOT + '/api/user-answer/' + quizToken, params)

      if (response.body.status == 'success') {

        localStorage.setItem('current-test', 'composing-image2')

        Vue.toastr({
          message: this.$t('successful'),
          description: this.$t('saved-successfully'),
          type: 'success'
        })
      } else {
        Vue.toastr({
          message: this.$t('error-title'),
          description: this.$t('error-saving'),
          type: 'error'
        })
      }

      setTimeout(() => {
        this.$router.push({ path: '/composing-image2' })
      }, 1500)

    },
    interpretScore(score) {
      if (score >= 30) {
        return this.interpretationResult.high;
      } else if (score >= 15) {
        return this.interpretationResult.middle;
      } else {
        return this.interpretationResult.low;
      }
    },
    startTimer(duration) {
      let timer = duration, minutes, seconds;
      this.timerInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.countdown = minutes + ":" + seconds;

        let savedTimer = (parseInt(minutes) * 60) + parseInt(seconds);
        localStorage.setItem('timerCreativitySubtest2', savedTimer);

        if (--timer < 0) {
          if (this.currentSubtest == 1) {

            setTimeout(() => {
              this.nextSubtest(true)
            }, 800)

          } else {
            this.sendCreativitySubtest2Results(true)
          }

          clearInterval(this.timerInterval);
        }
      }, 1000);
    },
  },
  mounted() {
    if (localStorage.getItem('timerCreativitySubtest2')) {
      this.startTimer(localStorage.getItem('timerCreativitySubtest2'))
    } else {
      this.startTimer(this.timerNumber)
    }

    if (localStorage.getItem('currentSubtest')) {
      this.currentSubtest = (localStorage.getItem('currentSubtest'))
    } else {
      localStorage.setItem('currentSubtest', "1")
    }

    if (localStorage.getItem('current-test')) {
      this.$router.push({name: localStorage.getItem('current-test')});
    }


    const testLang = localStorage.getItem('test_lang');
    if (testLang) this.$i18n.locale = testLang;
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  }
}
</script>

<style>
.select-custom-collaboration {
  width: 240px !important;
}

.collaboration-desc {
  margin: 20px 0;
}

.collaboration-options {
  cursor: pointer;
  position: relative;
}

.el-select-group__title {
  position: relative;
}

.el-select-group__title::after {
  position: absolute;
  right: 10px;
  top: 2px;
  content: '▼';
}


</style>
